(function($) {
	almHandling = function() {
		  $('.alm-filter').on('click', function(e) {
			 if(Foundation.MediaQuery.is('small only') || Foundation.MediaQuery.is('medium only')) {
				 if($(this).find('ul').hasClass('revealed')) {
					 $(this).find('ul').removeClass('revealed');
				 } else {
					 $('ul').removeClass('revealed');

    				 $(this).find('ul').toggleClass('revealed');
				 }
			 }
		  });

		  $('ul.revealed').on('click', function(e) {
			  if(Foundation.MediaQuery.is('small only') || Foundation.MediaQuery.is('medium only')) {
				  $(this).removeClass('revealed');
			  }
		  });

		  var filterYearLabel = $(".filters").data('filter-year-label');
		  var filterNewsLabel = $(".filters").data('filter-news-label');

		  var filterProjectClientsLabel = $(".filters").data('filter-clients-label');
		  var filterCategoriesLabel = $(".filters").data('filter-categories-label');

		  $("#alm-filters-news .alm-filter--year").prepend("<div class='filter-label custom'>" + filterYearLabel + " <i class='far fa-angle-down'></i></div>").attr('data-label', 'year');

          $("#alm-filters-news .alm-filter--category").prepend("<div class='filter-label custom'>" + filterNewsLabel + " <i class='far fa-angle-down'></i></div>").attr('data-label', 'news');

		  $("#alm-filters-projects .alm-filter--taxonomy[data-taxonomy='project_clients']").prepend("<div class='filter-label custom'>" + filterProjectClientsLabel + " <i class='far fa-angle-down'></i></div>").attr('data-label', 'clients');

          $("#alm-filters-projects .alm-filter--taxonomy[data-taxonomy='project_categories']").prepend("<div class='filter-label custom'>" + filterCategoriesLabel + " <i class='far fa-angle-down'></i></div>").attr('data-label', 'categories');

		  $("#alm-filters-reports .alm-filter--year").prepend("<div class='filter-label custom'>" + filterYearLabel + " <i class='far fa-angle-down'></i></div>").attr('data-label', 'year');

          $("#alm-filters-reports .alm-filter--taxonomy").prepend("<div class='filter-label custom'>" + filterCategoriesLabel + " <i class='far fa-angle-down'></i></div>").attr('data-label', 'categories');

		  //Change label
		  $('.alm-filter--link').on('click', function(e) {
			  var $filterLabelText;

			  if($(this).parent().is(":first-child")) {
				  var $labelEl = $(this).parents(".alm-filter").data('label');

				  $filterLabelText = $(this).parents(".filters").data('filter-' + $labelEl + '-label') + ' <i class="far fa-angle-down"></i>';
			  } else {
				  $filterLabelText = $(this).text() + ' <i class="far fa-angle-down"></i>';
			  }

			  $(this).parents('.alm-filter').find('.filter-label').html($filterLabelText);
		  });

		  $(".alm-filter--link.active").each(function(i) {
			  if(!$(this).parent().is(":first-child")) {
				  $(this).parents('.alm-filter').find('.filter-label').html($(this).text() + ' <i class="far fa-angle-down"></i>');
			  }
		  });
	};
})(jQuery);
