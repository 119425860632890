/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                $(document).foundation(); // Foundation JavaScript
                //init webnerds functions
                cookieConsent();
                stickyHeader();
                mobileNav();
                scrollHandling();
                siteHeaderSubMenu();
                questionsAndAnswers();
                gallery();
                instagramViewer();
                initSlickCarousel();
                bannerVideoClick();
                almHandling();
                //headerNav();
                bannerNewsletterBox();

                if (window.location.href.indexOf("mc4wp") > -1 && window.location.href.indexOf("mc4wp-banner") <= -1) {
                    setTimeout(
                        function () {
                          if($('.newsletter').length) {
                            $('html, body').animate({
                              scrollTop: $('.newsletter').offset().top
                            }, 500, 'linear');
                          }
                        },
                        1000
                    );
                }

                if ($(".scroll-to-content")) {
                    var $href = $(".scroll-to-content a").attr("href");

                    $(".scroll-to-content a").attr("href", $href + "?scroll-to-content");
                }

                if (window.location.href.indexOf("scroll-to-content") > -1) {
                    $('html, body').animate({
                        scrollTop: $(".flexible-content").offset().top - 40
                    });
                }

                if (Foundation.MediaQuery.is('small only') || Foundation.MediaQuery.is('medium only')) {
                    scrollStateFadeElements();
                }

                $.fn.almComplete = function (alm) {
                    if (Foundation.MediaQuery.is('small only') || Foundation.MediaQuery.is('medium only')) {
                        scrollStateFadeElements();
                    }
                };

                var $closeBtnLabel = $('.gallery-parent').data('close-btn-label');

                if (Foundation.MediaQuery.is('medium')) {
                    $('.article-gallery').magnificPopup({
                        type: 'image',
                        mainClass: 'mfp-fade',
                        gallery: {
                            enabled: true,
                            arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"><i class="fal fa-chevron-%dir%"></i></button>'
                        },
                        closeOnBgClick: false,
                        closeMarkup: '<button title="%title%" type="button" class="mfp-close">' + $closeBtnLabel + '</button>',
                        callbacks: {
                            change: function () {
                                if (this.isOpen) {
                                    this.wrap.addClass('mfp-open');
                                }
                            },
                            imageLoadComplete: function (data) {
                                var magnificPopup = $.magnificPopup.instance;
                            },
                            updateStatus: function (data) {
                                var magnificPopup = $.magnificPopup.instance;

                                if (data.status === "ready") {
                                    var galleryItems = magnificPopup.items;

                                    var $dots = "";

                                    for (i = 0; i < galleryItems.length; i++) {
                                        $dots += "<div class='dot'><i class='fas fa-circle'></i></div>";
                                    }

                                    if (!$(".mfp-bottom-bar .dot-nav").length) {
                                        $(".mfp-bottom-bar").prepend("<div class='dot-nav'>" + $dots + "</div>");

                                        $(".mfp-bottom-bar .dot").click(function (target) {
                                            $dotIndex = $(".dot").index(this);

                                            magnificPopup.goTo($dotIndex); //navigate to image
                                        });
                                    }

                                    $activeIndex = magnificPopup.currItem.index + 1;

                                    $('.dot-nav .dot').removeClass('active');

                                    $(".mfp-bottom-bar .dot:nth-child(" + $activeIndex + ")").addClass('active');
                                }
                            }
                        }
                    });
                }

                if (Foundation.MediaQuery.is('small only')) {
                    $('.article-gallery').on("click", function (e) {
                        e.preventDefault();
                    });
                }

                $('.image-caption').show();

                $('body').one('mousemove', function (e) {
                    var goingto = e.target;

                    if (!$(goingto).hasClass('.site-header') && !$(goingto).parents('.site-header').length && !$(goingto).hasClass('.banner') && !$(goingto).parents('.banner').length) {
                        $('.image-caption').fadeOut();
                    }
                });

                $('.banner, .site-header').on({
                    mouseenter: function (e) {
                        if (!$(".image-caption").is(":visible")) {
                            $('.image-caption').fadeIn();
                        }
                    },
                    mouseleave: function (e) {
                        var goingto = e.relatedTarget || e.toElement;

                        if (!$(goingto).hasClass('.site-header') && !$(goingto).parents('.site-header').length && !$(goingto).hasClass('.banner') && !$(goingto).parents('.banner').length) {
                            $('.image-caption').fadeOut();
                        }
                    }
                });

                //Entry meta phone numbers
                var entryMeta = $(".entry-meta").html();

                if (entryMeta) {
                    entryMeta = entryMeta.replace(/(?:\+?(\d{1,4}))?([-. (]*(\d{1,3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)/g, "<a href='tel:$&'>$&</a>");

                    $(".entry-meta").html(entryMeta);
                }
            },
            finalize: function () {
            // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
            // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
        'flex_content': {
            init: function () {
                // Set height of .video-upload, only for medium and bigger screen sizes
                var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                if (viewportWidth >= 640) {
                    setTimeout(function () {
                        var h = $('.flexible-content .image-text .content').outerHeight();
                        $('.flexible-content .image-text .video-upload .mejs-container').height(h);
                    }, 1000);
                }
            }
        },

        finalize: function () {
            // JavaScript to be fired on the home page, after the init JS
        }
    };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

  // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
