(function($) {
	autoScrollToAnchor = function() {
		$('a').click(function(event){
			var hash = $(this).prop("hash");
			var linkWithoutHash = $(this).prop("href").replace(/\#.*$/, '');
			var locationWithoutHash = window.location.href.replace(/\#.*$/, '');

			//if the link leads to this page and there is a hash part then scroll to element on same page
			if(linkWithoutHash === locationWithoutHash && /^#/.test(hash) === true) {
				event.preventDefault();
				history.pushState(null,null,hash);
				$('html,body').stop(true,false);
				$(hash).scrollToBySpeed({
					speed: 2000,
					offset: 200
				});
					/* do not run AJAX function */
			}
		});
	};
	scrollToTop = function(){
		$('.scrollToTop').off('click');
		$('.scrollToTop').click(function(e){
			$('html,body').animate({ scrollTop: 0 });
		});
	};

	scrollStateFadeElements = function(){
		var oTop;
		var oBottom;
		var wWidth = $(window).width();
		var wHeight = $(window).height();

		if (wHeight >= 670  && wWidth <= 599) {
			oTop = 150;
			oBottom = 300;
		} else if (wHeight >= 400 && wWidth <= 599) {
			oTop = 100;
			oBottom = 100;
		} else if (wHeight >= 800 && wWidth >= 760) {
			oTop = 150;
			oBottom = 300;
		} else if (wHeight >= 670  && wWidth <= 759) {
			oTop = 100;
			oBottom = 150;
		} else {
			oTop = 0;
			oBottom = 0;
		}

		var sr = new ScrollReveal({
			easing:'ease-in-out',
			reset:true,
			scale:1,
			viewFactor: 0.9,
			viewOffset: {top: oTop, right: 0, bottom: oBottom, left: 0}
		});
		sr.reveal('.overlay.scroll-state');

	};

	scrollHandling = function(){
		autoScrollToAnchor();
		scrollToTop();
	};
})(jQuery);
