(function($) {
	stickyHeader = function() {

		var $headerNavPos = $(".site-header .header-nav").offset();
		var $banner = $(".banner");

		if($banner.length) {
			var $bannerBottom = $banner.position().top + $banner.outerHeight(true) - 46;
		}

		var $headerIsTransparent = false;
		var $logoIsHidden = false;

		if($(".header-nav").hasClass('hidden-logo')) {
			$logoIsHidden = true;
		}

		if($(".site-header").hasClass('transparent-header')) {
			$headerIsTransparent = true;
		}

		$('.site-header .mobile-nav-toggle').click(function(){
			$('.site-header').addClass('mobile-nav-opened');

			if($('body').hasClass('mobile-nav-open')) {
				$('body').addClass('mobile-nav-closed');
			} else {
				$('body').removeClass('mobile-nav-closed');
			}
	    });

		// if (Foundation.MediaQuery.is('small only') || Foundation.MediaQuery.is('medium only')) {
			// $(".site-header").addClass('sticky');
		// }

		$(window).scroll(function() {
			var $windowTopPos = $(window).scrollTop();

			//if (Foundation.MediaQuery.atLeast('large')) {
				if($headerNavPos.top < $windowTopPos) {
					$(".site-header").addClass('sticky');
				} else {
					$(".site-header").removeClass('sticky');
				}
			//}

			if($headerIsTransparent) {
				if($bannerBottom < $windowTopPos) {
					$(".site-header").removeClass('transparent-header');
				} else {
					$(".site-header").addClass('transparent-header');
				}
			}

			if($logoIsHidden) {
				if($bannerBottom < $windowTopPos) {
					$(".hidden-logo").find(".small-logo").show();
				} else {
					$(".hidden-logo").find(".small-logo").hide();
				}
			}
		});

	};
})(jQuery); // Fully reference jQuery after this point.
