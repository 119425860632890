(function($) {
	/**
	 * Pauses/resumes video
	 */
	toggleVideoPlayState = function(id){
		var $video = $('video#'+id);
		var $overlay = $('.overlay[dataId='+id+']');
		if (!$video.length){
			return;
		}
		if ($video[0].paused){
			$video[0].play();
			$overlay.removeClass('play');
		} else {
			$video[0].pause();
			$overlay.addClass('play');
		}
	};
	/**
	 * Disables autoplay on first click (tries to remove .autoplay-enabled, <video> may not have this class)
	 */
	disableAutoplayOnClick = function(id){
		var $video = $('.video#'+id);
		$video.removeClass('autoplay-enabled');
	};

	bannerVideoClick = function(){
		$('.slide video').click(function(e){
			var id = e.currentTarget.attributes.id;

			if (id){
				toggleVideoPlayState(id.value);
				disableAutoplayOnClick(id.value);
			}
		});
	};

	var $videoOnlySlide = $('.carousel-container .non-slick.video .autoplay-enabled');

	if ($videoOnlySlide.length){
		$videoOnlySlide[0].play();
		$videoOnlySlide.removeClass('play');
	}


})(jQuery);
