(function ($) {
    function closeBox(el)
    {
        el.removeClass("opened");
        el.addClass("closed");

        Cookies.set('newsletterBoxClosed', '1');

        setTimeout(function () {
            $(el).removeClass("animating");
        }, 400);

    }

    function openBox(el)
    {
        $(el).removeClass("closed");
        $(el).removeClass("initial");
        $(el).addClass("opened");

        $(el).addClass("animating");

        setTimeout(function () {
            $(el).removeClass("animating");
        }, 400);
    }

    bannerNewsletterBox = function () {
        var $newsletterBoxEl = $(".banner .newsletter-box");

        if (!$newsletterBoxEl.length) {
            return;
        }

        $newsletterBoxEl.find(".close-btn").on("click", function () {
            closeBox($(this).parents(".newsletter-box"));
        });

        if(Foundation.MediaQuery.is('small only')) {
          $newsletterBoxEl.addClass('closed initial');
          Cookies.set('newsletterBoxClosed', '1');
        }

        if (Foundation.MediaQuery.is('medium') || Foundation.MediaQuery.is('small')) {
            $newsletterBoxEl.find(".closed-label").on('click', function () {
                if ($newsletterBoxEl.hasClass('closed')) {
                    openBox($(this).parents('.newsletter-box'));
                }
            });
        }

        if (Foundation.MediaQuery.atLeast('large')) {
            $newsletterBoxEl.hover(function () {
              if(!$newsletterBoxEl.hasClass('animating') && $newsletterBoxEl.hasClass('closed')) {
                openBox($(this));
              }
            }, function () {
                if (!$newsletterBoxEl.hasClass('animating') && $newsletterBoxEl.hasClass('opened')) {
                    closeBox($(this));
                }

                setTimeout(function() {
                  if(!$newsletterBoxEl.is(":hover")) {
                    if($newsletterBoxEl.hasClass('opened')) {
                      closeBox($newsletterBoxEl);
                    }
                  }
                }, 500);
            });
        }

        var $banner = $(".banner");

        if ($banner.length) {
            var $bannerBottom = $banner.position().top + $banner.outerHeight(true) - 46;
        }

        $(window).scroll(function () {
            var $windowTopPos = $(window).scrollTop();

            if ($bannerBottom < $windowTopPos && !$newsletterBoxEl.hasClass('closed')) {
                closeBox($newsletterBoxEl);
            }
        });

        if ($newsletterBoxEl.find('.success-label').length) { //If form has been successfully submitted
            var cleanUri = location.protocol + "//" + location.host + location.pathname;
            window.history.replaceState({}, document.title, cleanUri);

            setTimeout(function () {
                $newsletterBoxEl.fadeOut();
            }, 4000);
        }
    };
})(jQuery);
