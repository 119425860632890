(function($) {
headerNav = function() {
    $(".web-menu li").on("click", "a", function(e) {
        var currentUrl = window.location.href;
        var linkHref = $(this).attr('href');

        if(currentUrl === linkHref) {
            e.preventDefault();

            $('html, body').animate({
                scrollTop: $(".flexible-content").offset().top - 40
            });
        }
    });
};
})(jQuery);
